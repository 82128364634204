import * as React from "react";
import Layout from "../../components/layout/layout";
import Seo from "../../components/seo";
import {graphql} from "gatsby";
import Flats from "../../components/flats/flats";
import * as styles from "./house.module.scss";
import gp1_1 from "../../../static/images/1_1.png";
import gp1_2 from "../../../static/images/1_2.png";

const House1Page = ({data}) => {
  const flats = data.api.flats.filter((flat) => flat.id.startsWith("1"))
  const gpImages = [gp1_1, gp1_2];
  const maxFloor = 2;
  const gpWidth = 1460;
  const flatsOnGP = [
    {
      id: "1A",
      floor: 1,
      textCenter: "300,700",
      coords: "521,117,521,489,626,489,624,931,604,931,604,1100,3,1100,3,117"
    },
    {
      id: "1B",
      floor: 1,
      textCenter: "1000,650",
      coords: "606,1097,1455,1101,1455,472,1334,471,1336,152,849,153,849,320,716,320,715,490,628,491,628,935,606,934"
    },
    {
      id: "1C",
      floor: 1,
      textCenter: "600,300",
      coords: "709,156,524,487"
    },
    {
      id: "1C",
      floor: 2,
      textCenter: "700,500",
      coords: "3,117,538,117,538,156,1334,156,1334,474,1261,476,1264,989,604,988,602,949,113,952,115,748,1,752"
    }
  ];

  return (
    <Layout contactInfo={data.api.contactInfo}>
      <Seo title="Dům I" />

      <section className={styles.wrapper}>
        <h1 className={styles.title}>Dům I</h1>
      </section>
      {flats.length !== 0 ?
        <Flats flats={flats}
               flatsOnGP={flatsOnGP}
               gpImages={gpImages}
               gpWidth={gpWidth}
               maxFloor={maxFloor}
        />
        :
        <h4 className={styles.message}>Nabídku bytů pro Vás připravujeme</h4>
      }
    </Layout>
  )
}

export const query = graphql`
    query House1PageQuery {
        api {
            contactInfo:contactSingleton {
                email
                phone
                name
            }
            flats:flatsCollection(sort: {_o: 1}) {
                id
                floor
                dispositions
                area
                terrace
                garden
                garage
                price
                state
            }
        }
    }
`;

export default House1Page;
